.appRoot {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.appHeader {
  width: 100%;

  .siteName {
    flex-grow: 1;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.6;
    margin: 0 0 2px;

    a {
      color: rgb(255, 255, 255); /* rgb(68, 68, 68); */
    }
  }
}

.appFooter {
  background-color: rgb(31, 31, 31);
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  flex: 0 1 auto;
  flex-wrap: wrap;
  transition-duration: 0.2s;
  transition-property: background-color, left, right;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  a {
    color: rgb(170, 170, 170);

    &:hover,
    &:active {
      color: rgb(255, 255, 255);
    }
  }
}

.justifyCenter {
  justify-content: center;
  text-align: center;
}

.appMain {
  backface-visibility: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 56px 0 20px;
  transition: all 1.2s ease;
  width: 100%;
}

.contentPad {
  padding-left: 16px;
  padding-right: 16px;
}

.contentTop {
  background-color: rgb(35, 20, 55);
  background-image: linear-gradient(
    135deg,
    rgb(35, 20, 55) 0%,
    rgb(51, 51, 51) 100%
  );
  padding: 24px 16px;
  color: rgb(222, 222, 222);

  h2 {
    color: rgb(222, 222, 222);
  }

  a {
    color: rgb(222, 222, 222);

    &:hover {
      color: rgb(255, 255, 255);
    }
  }

  .updateNote {
    padding: 35px 0 25px;
    color: rgb(223, 223, 239);
    font-size: 0.8rem;
  }
}

.sectionLight {
  background-color: transparent;
  /* box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.15); */
  border-bottom: 1px solid rgb(222, 222, 222);
}

.sectionNormal {
  border-bottom: 1px solid rgb(222, 222, 222);
  background-color: rgb(239, 239, 239);
}

body {
  .appBar {
    background: rgb(40, 40, 42); /* rgb(255, 255, 255); */
  }

  .navLink {
    color: rgb(221, 221, 221); /* rgb(25, 25, 112); */
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:active {
      color: rgb(255, 255, 255); /* rgb(255, 140, 0); */
    }

    button {
      border-radius: 2px;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 1rem;
      font-weight: 700;

      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }
  }

  .drawerPaper {
    background: rgb(175, 11, 28);
    color: rgb(255, 255, 255);

    .drawerContentWrap {
      width: 240px;
    }

    .drawerContentHeader {
      background: rgb(63, 81, 181);
      padding: 13px 20px 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .drawerContentList {
      > a {
        color: rgb(255, 255, 255);
      }
    }

    .drawerItemIcon {
      color: rgb(255, 255, 255);
      min-width: 46px;
    }

    .drawerSvgIcon {
      font-size: 1.25rem;
    }
  }

  .caption {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.0333333333em;
    line-height: 1.25rem;
  }
}

.simpleTabs {
  background-color: rgb(243, 243, 243);
  flex-grow: 1;
}

.summaryStat {
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
  display: flex;
  font-weight: 700;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: normal;
  width: 100%;

  .stat {
    color: rgb(63, 63, 63);
    width: 100%;
    font-size: 27px;
    text-align: center;
  }

  .label {
    font-size: 11px;
    text-transform: uppercase;
    color: rgb(170, 183, 194);
    white-space: nowrap;
  }

  &.totalCasesStat {
    border-color: rgb(255, 69, 0);

    * {
      color: rgb(255, 69, 0);
    }
  }
}

.withoutCases {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  h4 {
    margin-top: 2rem;
  }

  .listItemWrap {
    justify-content: center;
  }

  .listItem {
    background-color: rgb(255, 255, 255);
    box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.15);
    padding: 8px 15px;
    margin: 5px;
  }
}

.latestNews {
  padding: 22px 0 35px;

  h2 {
    margin-bottom: 3rem;
  }

  .image {
    background-size: cover;
    height: 120px;
  }

  article {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    padding-bottom: 1px;
    margin-bottom: 24px;
    position: relative;
    font-size: 0.85rem;

    h3,
    p {
      padding: 0 20px;
    }

    h3 {
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }

    p {
      a {
        color: inherit;
      }
    }

    .postedDate {
      position: absolute;
      top: -15px;
      left: 15px;
      background-color: rgb(67, 52, 103);
      box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      color: rgb(255, 255, 255);
      display: inline-block;
      padding: 8px 16px;

      svg,
      span {
        position: relative;
        display: inline-block;
      }

      svg {
        font-size: 1rem;
        top: 2px;
      }

      span {
        top: -2px;
        margin-left: 5px;
      }
    }
  }
}

.viewMore {
  text-align: center;
  padding-top: 22px;
}

.inlineFlag {
  margin-right: 10px;
}

.footerDiv1 {
  padding: 12px 16px;
  background-color: rgb(32, 35, 42);
  color: rgb(204, 204, 204);
}

.footerDiv2 {
  color: rgb(222, 222, 222);
  padding: 12px 16px;
}

@media (min-width: 600px) {
  .appBar {
    .appBarToolbar {
      padding-left: 28px;
      padding-right: 28px;
    }
  }

  .appMain {
    padding: 64px 0 22px;
  }

  .contentPad {
    padding-left: 28px;
    padding-right: 28px;
  }

  .withoutCases {
    .listItemWrap {
      display: flex;
      flex-direction: row;
    }

    .listItem {
      display: inline-flex;
      flex-direction: row;
    }
  }
}
